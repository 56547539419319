import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, createTheme, Grid } from '@mui/material';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { existsWithLength, useFunctionCall } from '@aldridge/aldg-helpers';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { faBackward } from '@fortawesome/pro-solid-svg-icons';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';

const ProjectAlbums = (props) => {
  const { history } = props;
  const [rows, setRows] = useState([]);
  const match = useRouteMatch();
  const STUBPAGE = `${match.params.id}`;

  const CellSelect = (cell) => {
    if (cell.field === '__check__') return;
    history.push(`${STUBPAGE}/${cell}`);
  };

  const mounted = useRef(null);
  const { data, loading, error, functionCall } =
    useFunctionCall(`ProcoreAlbums`);

  useEffect(() => {
    if (match.params.id !== '') {
      functionCall({ projId: match.params.id });
    }
    return () => (mounted.current = false);
  }, [match.params.id]);

  const _theme = createTheme();
  const matches = useMediaQuery(_theme.breakpoints.down('sm'));
  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const columns = [
    {
      field: 'Name',
      headerName: 'Name',
      valueGetter: (params) => params.row.name,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'PhotoCount',
      headerName: 'Number of Photos in Album',
      valueGetter: (params) => params.row.count,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];

  useEffect(() => {
    if (existsWithLength(data)) {
      setRows(data);
    }
    console.log(data);
  }, [data, loading, error]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <ToolTipIconButton
          icon={faBackward}
          onClick={() => history.goBack()}
          label='Back to Jobs'
        />
      </Grid>
      <Grid item xs={12}>
        <AldgDataGrid
          columns={columns}
          containerHeight={rows.length > 11 ? '650px' : undefined}
          loading={loading}
          onRowClick={(a) => {
            CellSelect(`${a.row.id}`);
          }}
          pagination
          rows={rows}
        />
      </Grid>
    </Grid>
  );
};

ProjectAlbums.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
ProjectAlbums.defaultProps = {};

export default ProjectAlbums;
