/* eslint-disable no-unused-vars */
import {
  faHome,
  faDuck,
  faStroopwafel,
  faBurgerCheese,
  faGhost,
  faCatSpace
} from '@fortawesome/pro-regular-svg-icons';

const SidebarData = [
  // {
  //   href: '/', // link to location
  //   icon: faHome, // icon, from font awesome
  //   title: 'Home', // label
  //   dock: true, // true if want to appear on the bottom navigation bar on mobile
  // },
  // {
  //   href: '/duck', // link to location
  //   icon: faDuck, // icon, from font awesome
  //   title: 'Duck', // label
  //   dock: true, // true if want to appear on the bottom navigation bar on mobile
  // },
  // {
  //   href: '/stroopaloopadoop', // link to location
  //   icon: faStroopwafel, // icon, from font awesome
  //   title: 'Stroop', // label
  //   dock: false, // true if want to appear on the bottom navigation bar on mobile
  // },
  // {
  //   href: '/canihaz', // link to location
  //   icon: faBurgerCheese, // icon, from font awesome
  //   title: 'Borger', // label
  //   dock: true, // true if want to appear on the bottom navigation bar on mobile
  // },
  // {
  //   href: '/boooooooooooooooooooooo', // link to location
  //   icon: faGhost, // icon, from font awesome
  //   title: 'Boo', // label
  //   dock: false, // true if want to appear on the bottom navigation bar on mobile
  // },
  // {
  //   href: '/KITTY', // link to location
  //   icon: faCatSpace, // icon, from font awesome
  //   title: 'Me-out of this world', // label
  //   dock: false, // true if want to appear on the bottom navigation bar on mobile
  // },
  // reapeat these as necessary for sidebar/dock links
];
export default SidebarData;
