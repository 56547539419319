import { RecoilRoot, useRecoilValue, useSetRecoilState } from 'recoil';

import React from 'react';
import ReactDOM from 'react-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { AldgWrapper, ErrorBoundary } from '@aldridge/aldg-ui-components';
import { OfflineProvider } from '@aldridge/aldg-helpers';
import SidebarData from './_GlobalComponents/Menu/SideBarData';
import Application from './Pages/Application';
import UserProvider, { UserContext } from './providers/UserProvider';
import OfflineDataProvider from './providers/OfflineDataProvider';
import {
  config,
  signUserOut as signOut,
  firestore,
  functions,
  auth
} from './firebase';
import AldgTheme from './theme';

import './_assets/base.scss';
import 'react-toastify/dist/ReactToastify.css';
import '@aldridge/aldg-ui-components/dist/index.css';
import { _ShowChangelogModal } from './_Recoil/atoms';

const rootElement = document.getElementById('root');

const renderApp = () => {
  try {
    ReactDOM.render(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={AldgTheme}>
          <RecoilRoot>
            <OfflineProvider>
              <UserProvider>
                <ErrorBoundary
                  functions={functions}
                  firestore={firestore}
                  auth={auth}
                  componentName='Main Application'
                  user={{ email: '' }}
                >
                  <OfflineDataProvider>
                    <AldgWrapper
                      appTitle='Procore Image Stamper'
                      SidebarData={SidebarData}
                      AppVersion='1.0.0'
                      signOut={signOut}
                      firestore={firestore}
                      functions={functions}
                      UserContext={UserContext}
                      ShowCLModal={() => useRecoilValue(_ShowChangelogModal)}
                      auth={auth}
                      config={config}
                      SetShowChangelogModal={() =>
                        useSetRecoilState(_ShowChangelogModal)
                      }
                    >
                      <Application />
                    </AldgWrapper>
                  </OfflineDataProvider>
                </ErrorBoundary>
              </UserProvider>
            </OfflineProvider>
          </RecoilRoot>
        </ThemeProvider>
      </StyledEngineProvider>,
      rootElement
    );
  } catch (err) {
    // eslint-disable-next-line
    console.trace(err);
  }
};
renderApp();
