/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  useMediaQuery,
  createTheme,
  Grid,
  checkboxClasses,
  LinearProgress,
  Box,
  Typography
} from '@mui/material';
import { useGridApiRef, LicenseInfo } from '@mui/x-data-grid-premium';
import { toast } from 'react-toastify';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { InputSelect } from '@aldridge/aldg-data-components';
import { existsWithLength, useFunctionCall } from '@aldridge/aldg-helpers';
import { saveAs } from 'file-saver';
import watermark, { text } from 'watermarkjs';
import JSZip from 'jszip';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { faBackward } from '@fortawesome/pro-solid-svg-icons';
import Loader from 'react-loader-spinner';
import { UserContext } from '../providers/UserProvider';
import { env } from '../.env';

const CheckBoxComp = (item, checked, setChecked) => {
  const handleCheck = (event) => {
    const temp = { ...checked };
    temp[item.id] = { ...item, checked: event.target.checked };
    setChecked(temp);
    checked[item.id] = temp[item.id];
  };

  return (
    <div style={{ display: 'flex', alignContent: 'center' }}>
      <input
        name={item.id}
        id={item.id}
        type='checkbox'
        checked={checked[item.id] ? checked[item.id].checked : false}
        onChange={handleCheck}
        style={{ cursor: 'pointer' }}
      />
      <label htmlFor={item.id} style={{ wordBreak: 'break-word' }}>
        {item.location?.name ? item.location.name : '[na]'}_
        {item.description ? item.description : '[na]'}_
        {item.taken_at
          ? new Date(item.taken_at).toLocaleDateString().replace(/\//g, '_')
          : '[na]'}
        _[{item.id}]
      </label>
      {/* <label htmlFor={item.id}>{item.filename}</label> */}
    </div>
  );
};

LicenseInfo.setLicenseKey(env.DATA_GRID_LICENSE);

const ProjectPhotos = (props) => {
  const { history } = props;
  const user = useContext(UserContext);
  const [Data, setData] = useState([]);
  const [AlbumData, setAlbumData] = useState({});
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [checked, setChecked] = useState({});
  const [color, setColor] = useState('black');
  const [image, setImage] = useState();
  const [imagesGen, setImagesGen] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [progress, setProgress] = useState(0);
  const [colCount, setColCount] = useState(12);
  const GridApiRef = useGridApiRef();
  const match = useRouteMatch();

  const symbols = [
    '@',
    '!',
    '~',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '|',
    '+',
    '=',
    '?',
    ';',
    ':',
    "'",
    '"',
    '`',
    ',',
    '<',
    '>',
    '{',
    '}',
    '/',
    '\\'
  ];
  const STUBPAGE = 'ProjectPhotos';

  const mounted = useRef(null);
  const { data, loading, error, functionCall } =
    useFunctionCall(`ProcorePhotos`);
  const {
    data: albumData,
    loading: albumLoading,
    error: albumError,
    functionCall: albumCall
  } = useFunctionCall(`ProcoreAlbums`);
  const _theme = createTheme();

  useEffect(() => {
    if (window.innerWidth > 1000) {
      setColCount(12);
    } else if (window.innerWidth <= 1000 && window.innerWidth >= 600) {
      setColCount(6);
    } else if (window.innerWidth < 600) {
      setColCount(4);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (
      Object.entries(checked).filter((i) => i[1].checked === false).length > 0
    ) {
      setSelectAll(false);
    }
    if (
      Object.entries(checked)?.filter((i) => i[1]?.checked === true).length ===
        data?.length &&
      existsWithLength(data)
    ) {
      setSelectAll(true);
    }
  }, [checked]);

  useEffect(() => {
    if (match.params.id !== '' && match.params.cat !== '') {
      functionCall({ projId: match.params.id, projCat: match.params.cat });
      albumCall({ projId: match.params.id, AlbumId: match.params.cat });
    }
    return () => (mounted.current = false);
  }, [match.params.id, match.params.cat]);

  useEffect(() => {
    if (mounted.current) setRows(Data);
    return () => (mounted.current = false);
  }, [Data]);

  useEffect(() => {
    if (mounted.current && existsWithLength(albumData)) setAlbumData(albumData);
    return () => (mounted.current = false);
  }, [albumData]);

  useEffect(() => {
    if (existsWithLength(data)) {
      const updatedData = data.filter((i) =>
        i.url.includes('https://storage.procore.com')
      );
      setRows(updatedData);
      setData(updatedData);
    }
  }, [data, loading, error]);

  const CleanFileName = (s) => {
    // string.replace(/ ~!@#\$%\^&\*\|\+=\?;:'"`,.<>\{\}\//g, '_');}
    const temp = s.toString();
    let temp2 = '';
    symbols.forEach((symbol) => {
      temp2 = temp.replace(symbol, '');
    });

    return temp2;
  };

  const handleChange = (event) => {
    setColor(event.target.value);
  };

  const generateImg = (item) => {
    if (item.url.includes('https://storage.procore.com')) {
      return (
        <Grid item xs={2} key={`${item.filename}_${item.id}`} sx={{ p: 0.5 }}>
          {CheckBoxComp(item, checked, setChecked)}
          <img
            src={item.thumbnail_url}
            alt={item.filename}
            style={{ width: '100%', height: 'fit-content' }}
          />
        </Grid>
      );
    }
    return null;
  };

  const options = {
    init: (img) => {
      img.crossOrigin = 'anonymous';
    }
  };

  const createTextBox = (txt, ctx, font, x, y, rectSet) => {
    const fntSize = parseInt(font, 10);
    ctx.globalAlpha = 0.65;
    ctx.fillStyle =
      color === 'lime' || color === 'magenta' || color === 'white'
        ? 'black'
        : 'white';
    ctx.fillRect(0, rectSet, fntSize * 0.6 * txt.length, fntSize * 1.15);
    ctx.globalAlpha = 1;
    ctx.fillStyle = color;
    ctx.font = font;
    ctx.fillText(txt, x, y);
    return ctx;
  };

  const doOverlay = (item, count) =>
    new Promise((resolve, reject) => {
      const imgToDoLength = Object.entries(checked).filter(
        (i) => i[1].checked
      ).length;
      setProgress((count / imgToDoLength) * 100);
      let imgFileName = `${
        item[1].location?.name ? item[1].location.name : '[na]'
      }_${
        item[1].description
          ? `${item[1].description.split(' ').slice(0, 5).join(' ')}...`
          : '[na]'
      }_${
        item[1].taken_at
          ? new Date(item[1].taken_at).toLocaleDateString().replace(/\//g, '_')
          : '[na]'
      }_[${item[0]}]`;
      symbols.forEach((symbol) => {
        imgFileName = imgFileName.replaceAll(symbol, '-');
      });
      try {
        if (item[1].checked === true) {
          watermark([item[1].url], options)
            .image((img) => {
              const context = img.getContext('2d');
              const textLoc = `Location: ${
                item[1].location?.name ? item[1].location.name : 'NA'
              }`;
              // FORMATS DATE TO MM/DD/YYYY HH:MM
              const date = new Date(item[1].taken_at).toLocaleDateString(
                'en-US',
                {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                }
              );
              const textTime = `Date Taken On: ${
                item[1].taken_at ? date : 'NA'
              }`;
              const textDesc = `Description: ${
                item[1].description ? item[1].description : 'NA'
              }`;
              // const textLat = `Latitude: ${
              //   item[1].gps_lat ? item[1].gps_lat : 'NA'
              // }`;
              // const textLong = `Longitude: ${
              //   item[1].gps_long ? item[1].gps_long : 'NA'
              // }`;
              const textFile = `${item[1].filename ? item[1].filename : 'NA'}`;
              context.save();

              // Upper text generation
              createTextBox(
                textDesc,
                context,
                `${item[1].width * 0.03}px Arial`, // 120
                20,
                item[1].width * 0.03, // 120
                0
              );
              createTextBox(
                textLoc,
                context,
                `${item[1].width * 0.025}px Arial`, // 80
                20,
                item[1].width * 0.03 +
                  item[1].width * 0.025 +
                  item[1].width * 0.025 * 0.1, // 220
                item[1].width * 0.025 * 1.4 // 150
              );
              createTextBox(
                textTime,
                context,
                `${item[1].width * 0.02}px Arial`, // 70
                20,
                item[1].width * 0.03 +
                  item[1].width * 0.025 +
                  item[1].width * 0.02 +
                  item[1].width * 0.025 * 0.3, // 310
                item[1].width * 0.02 * 3 + item[1].width * 0.02 * 0.3 // 250
              );
              // Lower text generation
              // createTextBox(
              //   textLat,
              //   context,
              //   `${item[1].width * 0.02}px Arial`, // 60
              //   20,
              //   img.height - item[1].width * 0.02 * 3, // img.height - 180
              //   img.height - item[1].width * 0.02 * 3 * 1.33 // img.height - 240
              // );
              // createTextBox(
              //   textLong,
              //   context,
              //   `${item[1].width * 0.02}px Arial`, // 60
              //   20,
              //   img.height - item[1].width * 0.02 - item[1].width * 0.02 * 0.6, // img.height - 100
              //   img.height - item[1].width * 0.02 * 2.65 // img.height - 160
              // );
              createTextBox(
                textFile,
                context,
                `${item[1].width * 0.02}px Arial`, // 60
                20,
                img.height - item[1].width * 0.02 * 0.33, // img.height - 20
                img.height - item[1].width * 0.02 * 1.33 // img.height - 80
              );
              context.restore();
              return context.canvas;
            })
            .then((img) => {
              setImage(img.src);
              setImagesGen((prev) => [
                ...prev,
                {
                  src: img.src,
                  name: imgFileName
                }
              ]);
              resolve({
                src: img.src,
                name: imgFileName
              });
            })
            .catch((err) => {
              console.log(err);
              toast.error(
                `Something went wrong. Couldn't download image ${imgFileName}. Error: "${err}".`,
                { autoClose: 10000 }
              );
            });
        } else {
          resolve();
        }
      } catch (err) {
        toast.error(
          `Something went wrong. Couldn't download images. Error: "${err}".`,
          { autoClose: 10000 }
        );
        console.log(err);
        resolve();
      }
    });

  const saveZip = (filenm, urls) => {
    const chunkSize = 70;
    for (let i = 0; i < urls.length; i += chunkSize) {
      const chunk = new JSZip();
      urls.slice(i, i + chunkSize).map((item) => {
        if (item) {
          const n = item.name.includes('.jpg')
            ? item.name.slice(0, item.name.length - 4)
            : item.name;
          chunk.file(`${n}.png`, item.src.split(',')[1], {
            base64: true
          });
        }
        return 'null';
      });
      chunk.generateAsync({ type: 'blob' }).then((blob) => {
        saveAs(blob, filenm);
      });
    }
    setProgress(0);
  };

  const checkImg = async () => {
    const promises = [];
    setProgress(0);
    if (Object.entries(checked).filter((i) => i[1].checked).length === 0) {
      toast.info('No Images Selected.', { autoClose: 3000 });
      return;
    }
    if (checked) {
      const arrPhotos = Object.entries(checked).filter((i) => i[1].checked);
      for (let i = 0; i < arrPhotos.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        promises.push(await doOverlay(arrPhotos[i], i + 1));
        setTimeout(() => {}, 2000);
      }
    }
    Promise.all(promises).then((res) => {
      try {
        let str = `${albumData?.links.show.split('/')[1]}_${
          albumData?.name
        }`.replaceAll('/', '_');
        symbols.forEach((symbol) => {
          str = str.replaceAll(symbol, '-');
        });
        saveZip(str, res);
      } catch (err) {
        toast.error('Failed to zip up images.');
        console.log(err);
      }
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (selectAll === false) {
      Data.map((item) => {
        const temp = { ...checked };
        temp[item.id] = { ...item, checked: true };
        setChecked(temp);
        checked[item.id] = temp[item.id];
        return '';
      });
    } else {
      Data.map((item) => {
        const temp = { ...checked };
        if (temp[item.id]) {
          temp[item.id] = { ...item, checked: false };
        }
        setChecked(temp);
        checked[item.id] = temp[item.id];
        return '';
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2>
          Overlaying Image Data for Album &quot;{albumData?.name || ''}&quot;
        </h2>
        <ToolTipIconButton
          icon={faBackward}
          onClick={() => history.goBack()}
          label='Back to Albums'
        />

        {progress === 0 ? (
          <Grid
            container
            style={{ display: 'flex', alignItems: 'center', paddingTop: '8px' }}
          >
            <Grid item xs={5}>
              <Box
                sx={{
                  '& .MuiSelect-select': {
                    fontWeight: 'bold',
                    color: color === 'white' ? 'darkgray' : color
                  },
                  paddingRight: 20
                }}
              >
                <InputSelect
                  name='ColorSel'
                  label='Select color for Overlay:'
                  onChange={handleChange}
                  value={color}
                  options={[
                    { value: 'black', label: 'Black' },
                    { value: 'blue', label: 'Blue' },
                    { value: 'lime', label: 'Lime' },
                    { value: 'magenta', label: 'Magenta' },
                    { value: 'red', label: 'Red' },
                    { value: 'white', label: 'White' }
                  ]}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              Select all:
              <input
                name='selectAll'
                type='checkbox'
                checked={selectAll}
                onChange={handleSelectAll}
                style={{
                  cursor: 'pointer',
                  marginLeft: 40
                }}
              />
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'right' }}>
              <Button onClick={checkImg} variant='contained'>
                Download Image(s)
              </Button>
            </Grid>
          </Grid>
        ) : (
          <div style={{ width: '100%' }}>
            <LinearProgress
              variant={progress === 100 ? 'indeterminate' : 'determinate'}
              value={progress}
              style={{ marginTop: '15px' }}
            />
            <Typography>
              {progress === 100 ? '' : `${Math.round(progress)}%`}
            </Typography>
          </div>
        )}
        {loading ? (
          <Grid container>
            <Grid item xs={12}>
              <Loader type='TailSpin' />
            </Grid>
          </Grid>
        ) : null}
        <Grid container columns={colCount}>
          {Data.map(generateImg)}
        </Grid>
      </Grid>
    </Grid>
  );
};

ProjectPhotos.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
ProjectPhotos.defaultProps = {};

export default ProjectPhotos;
