import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from '@aldridge/aldg-ui-components';
import { UserContext } from '../providers/UserProvider';
import SignIn from './SignIn';
import Unauthorized from './Unauthorized';
import NoMatch from './NoMatch';
import { functions, firestore, auth } from '../firebase';
import Changelog from '../_GlobalComponents/Changelog/Changelog';
import ChangelogList from '../_GlobalComponents/Changelog/ChangelogList';
import _StubList from './_Stubs/_StubListPage';
import _Stub from './_Stubs/_Stub';
import Test from './Test';
import ProjectsList from './ProjectsList';
import ProjectAlbums from './ProjectAlbums';
import ProjectPhotos from './ProjectPhotos';

const Application = () => {
  const user = useContext(UserContext);
  const page = () => {
    if (!user) {
      return <SignIn />;
    }
    if (user && (user.admin || user.user)) {
      return (
        <>
          <Switch>
            <Route exact path='/' component={ProjectsList} />
            <Route exact path='/_Stub' component={_StubList} />
            <Route exact path='/_Stub/:id' component={_Stub} />
            <Route exact path='/Test' component={Test} />
            <Route exact path='/ProjectsList' component={ProjectsList} />
            <Route exact path='/ProjectsList/:id' component={ProjectAlbums} />
            <Route
              exact
              path='/ProjectsList/:id/:cat'
              component={ProjectPhotos}
            />
            <Route
              exact
              path='/Changelog'
              component={user.SuperAdmin ? ChangelogList : NoMatch}
            />
            <Route
              exact
              path='/Changelog/:id'
              component={user.SuperAdmin ? Changelog : NoMatch}
            />

            <Route exact>
              <NoMatch />
            </Route>
          </Switch>
        </>
      );
    }

    return <Unauthorized />;
  };

  return (
    <ErrorBoundary
      componentName='The Whole Application'
      functions={functions}
      firestore={firestore}
      auth={auth}
    >
      <div className='appBody'>{page()}</div>
    </ErrorBoundary>
  );
};
export default Application;
