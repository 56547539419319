import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, createTheme, Grid } from '@mui/material';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { existsWithLength, useFunctionCall } from '@aldridge/aldg-helpers';
import { useGridApiRef } from '@mui/x-data-grid-premium';

const ProjectsList = (props) => {
  const { history } = props;
  const [rows, setRows] = useState([]);
  const STUBPAGE = 'ProjectsList';

  const CellSelect = (cell) => {
    if (cell.field === '__check__') return;
    history.push(`/${STUBPAGE}/${cell}`);
  };

  const mounted = useRef(null);
  const { data, loading, error, functionCall } = useFunctionCall(`ProcoreJobs`);

  useEffect(() => {
    functionCall();
    return () => (mounted.current = false);
  }, []);
  const apiRef = useGridApiRef();
  const _theme = createTheme();
  const matches = useMediaQuery(_theme.breakpoints.down('sm'));
  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const getAddress = (adr) => {
    let fullAdr = '';
    if (adr.street) fullAdr = adr.street;
    if (adr.city) fullAdr = `${fullAdr}, ${adr.city}`;
    if (adr.state_code) fullAdr = `${fullAdr}, ${adr.state_code}`;
    if (adr.zip) fullAdr = `${fullAdr}, ${adr.zip}`;
    if (fullAdr[0] === ',') fullAdr = fullAdr.slice(1);
    return fullAdr;
  };
  const columns = [
    {
      field: 'ProjectID',
      headerName: 'Project ID',
      valueGetter: (params) => params.row.id,
      flex: getFlex(5),
      width: getWidth(100)
    },
    {
      field: 'Name',
      headerName: 'Name',
      valueGetter: (params) => params.row.name,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Address',
      headerName: 'Address',
      valueGetter: (params) => getAddress(params.row.address),
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Status',
      headerName: 'Status',
      valueGetter: (params) => params.row.status_name,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];
  useEffect(() => {
    if (existsWithLength(data)) {
      setRows(data);
    }
  }, [data, loading, error]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <AldgDataGrid
          apiRef={apiRef}
          containerHeight='650px'
          columns={columns}
          loading={loading}
          onRowClick={(a) => CellSelect(`${a.row.id}`)}
          pagination
          rows={rows}
        />
      </Grid>
    </Grid>
  );
};

ProjectsList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
ProjectsList.defaultProps = {};

export default ProjectsList;
