/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Paper,
  useMediaQuery,
  Card,
  CardContent,
  checkboxClasses
} from '@mui/material';
import {
  InputAutocomplete,
  InputRadio,
  InputRadioGroup,
  InputDate,
  InputText
} from '@aldridge/aldg-data-components';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import JSZip from 'jszip';
import { doc, collection, query, onSnapshot, setDoc } from 'firebase/firestore';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import watermark, { text } from 'watermarkjs';
import { getUnit } from '@mui/material/styles/cssUtils';
import axios, { isCancel, AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { firestore, firebaseConfig } from '../firebase';
import { UserContext } from '../providers/UserProvider';

const CheckBoxComp = (item, checked, setChecked) => {
  // const nm = item.slice(item.lastIndexOf('-') + 1);
  const handleCheck = (event) => {
    // if (event.value === item.value) setChecked(!checked);
    const temp = { ...checked };
    temp[item] = event.target.checked;
    setChecked(temp);
  };

  return (
    <input
      name={item}
      type='checkbox'
      checked={checked[item] || false}
      onChange={handleCheck}
      style={{ cursor: 'pointer' }}
    />
  );
};

const Test = (props) => {
  const URLArr = [
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/036ED58C-4115-4999-8875-5BCCD9186CEA.jpeg',
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/0E26A17F-ECDB-4D5F-826F-3DE7A44FB0CE.jpeg',
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/42EB9FA5-BAED-4313-A099-4C462701C7E9.jpeg',
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/45FEE8C8-A301-4DD2-91F4-E408133E9F8B.jpeg',
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/5BCBBC82-3987-4D15-8003-3B9103309E5A.jpeg',
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/7BA4D35A-09C9-4956-BA46-2A10ECC79DAF.jpeg',
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/AC4C4870-68A1-44B3-976D-7A1E236C2AA7.jpeg',
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/D4AE53A4-6038-4515-A858-B0DFA6F6AE98.jpeg',
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/E1E867DD-9912-46C9-9CBF-79722BA5A5F2.jpeg',
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/F182CA96-853B-45AD-B6DB-24EFF200EF31.jpeg',
    'https://storage.googleapis.com/aldg-procore-samples/Viking%20Yard/FE1129D1-10BA-47BD-AF2B-9FD559BF05DC.jpeg'
  ];
  const AuthUrl = 'https://login.procore.com/oauth/token';
  const GrantType = 'client_credentials';
  const ClientId =
    '2ce20f79dbf044dd94ac063293e098fa9caa3d40386f04e896bf0029494c9313';
  const ClientSecret =
    'bfd7d92eaadbaf9559078145c60e0b837604415bad581aa92274c2f123b46db3';

  const CompanyId = '4479';
  const GetUrl = `https://api.procore.com/rest/v1.0/companies/${CompanyId}/projects?company_id=4479`;
  const [imagesGen, setImagesGen] = useState([]);

  const { history, match } = props;
  const user = useContext(UserContext);
  const [Saving, setSaving] = useState(false);
  const [Record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    JobNumber: '',
    data: {}
  });
  const [image, setImage] = useState();
  const [color, setColor] = useState('black');
  const [checked, setChecked] = useState({});

  const handleChange = (event) => {
    setColor(event.target.value);
  };

  const mounted = useRef(null);
  const theme = useTheme();

  const { data, loading, error, functionCall } = useFunctionCall(`ProcoreJobs`);

  const STUBPAGE = 'Test';
  const STUBCOLLECTION = 'COLLECTION';

  useEffect(() => {
    // const form = new FormData();
    // form.append('grant_type', `${GrantType}`);
    // form.append('client_id', `${ClientId}`);
    // form.append('client_secret', `${ClientSecret}`);
    // axios.post(`${AuthUrl}`, form);
    functionCall();
    console.log('log');
    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    console.log([data, loading, error]);
  }, [data, loading, error]);
  const goBack = () => {
    history.push(`/${STUBPAGE}`);
  };

  const handleSave = () => {
    setSaving(true);
    try {
      if (match.params.id === 'new') {
        const docRef = doc(collection(firestore, STUBCOLLECTION));
        Record.id = docRef.id;
        // setDoc(docRef, Record, { merge: true }).catch((err) => {
        //   toast.error(err);
        // });
        history.push(`/${STUBPAGE}/${Record.id}`);
        setSaving(false);
      } else {
        const uRecord = { ...Record };
        uRecord.ModifiedBy = user.email;
        uRecord.ModifiedDate = new Date().toJSON();
        const docRef = doc(firestore, STUBCOLLECTION, uRecord.id);
        // setDoc(docRef, uRecord, { merge: true }).catch((err) => {
        //   toast.error(err);
        // });
        history.push(`/${STUBPAGE}`);
        setSaving(false);
      }
      toast.success(`${STUBPAGE} saved.`);
    } catch (err) {
      setSaving(false);
      toast.error(err.toString());
    }
  };

  // watermark(
  //   [
  //     'https://storage.googleapis.com/manholes/0000/2020-06-03T15%3A02/IMG-0001-1604511359830-image.jpg?not-in-cache',
  //   ],
  //   options
  // )

  // location
  // description
  // gps
  // taken on
  // filename
  const options = {
    init: (img) => {
      img.crossOrigin = 'anonymous';
    }
  };

  const createTextBox = (txt, ctx, font, x, y, rectSet) => {
    const fntSize = parseInt(font, 10);
    ctx.globalAlpha = 0.65;
    ctx.fillStyle =
      color === 'lime' || color === 'magenta' || color === 'white'
        ? 'black'
        : 'white';
    ctx.fillRect(0, rectSet, fntSize * 0.6 * txt.length, fntSize * 1.15);
    ctx.globalAlpha = 1;
    ctx.fillStyle = color;
    ctx.font = font;
    ctx.fillText(txt, x, y);
    return ctx;
  };

  const doOverlay = (item) =>
    new Promise((resolve, reject) => {
      if (item[1] === true) {
        watermark([item[0]], options)
          .image((img) => {
            const context = img.getContext('2d');
            const textLoc = 'Location: Place #3';
            const textTime = 'Taken On: May 24, 2023 at 09:42:59 AM';
            const textDesc = 'Description--------------------------Description';
            const textLat = 'Lat: 44.00000000000000';
            const textLong = 'Long: -93.00000000000000';
            const textFile = `${item[0].slice(item[0].lastIndexOf('-') + 1)}`;

            context.save();
            createTextBox(textDesc, context, '120px Arial', 20, 120, 0);
            createTextBox(textLoc, context, '80px Arial', 20, 220, 150);
            createTextBox(textTime, context, '70px Arial', 20, 310, 250);

            createTextBox(
              textLat,
              context,
              '60px Arial',
              20,
              img.height - 180,
              img.height - 240
            );
            createTextBox(
              textLong,
              context,
              '60px Arial',
              20,
              img.height - 100,
              img.height - 160
            );
            createTextBox(
              textFile,
              context,
              '60px Arial',
              20,
              img.height - 20,
              img.height - 80
            );
            context.restore();
            return context.canvas;
          })
          // .image(text.upperLeft('Location: Place #3', '120px Arial', color, 1.0, 100)).render()
          // .image(text.upperLeft('Taken On: May 24, 2023 at 09:42:59 AM', '120px Arial', color, 1.0, 240)).render()
          // .image(text.upperLeft('Description---------------------------------------Description', '80px Arial', color, 1.0, 360))
          .then((img) => {
            setImage(img.src);
            setImagesGen((prev) => [
              ...prev,
              {
                src: img.src,
                name: item[0].slice(item[0].lastIndexOf('-') + 1)
              }
            ]);
            resolve({
              src: img.src,
              name: item[0].slice(item[0].lastIndexOf('-') + 1)
            });
            console.log(`Image Generated Overlay in ${color}`);
            // img.onload = () => {
            //   // create Canvas
            //   const canvas = document.createElement('canvas');
            //   const ctx = canvas.getContext('2d');
            //   canvas.width = img.width;
            //   canvas.height = img.height;
            //   ctx.drawImage(img, 0, 0);
            //   // create a tag
            //   const a = document.createElement('a');
            //   a.download = `${item[0].slice(item[0].lastIndexOf('-') + 1)}.png`;
            //   a.href = canvas.toDataURL('image/png');
            //   a.click();
            // };
          })
          .catch((err) => console.log(err));
      } else {
        resolve();
      }
    });

  const generateImg = (item) => (
    <Grid item xs={2} key={item}>
      <img
        src={item}
        alt={item}
        style={{ width: '100%', height: 'fit-content' }}
      />
      {CheckBoxComp(item, checked, setChecked)}
    </Grid>
  );

  const saveZip = (filename, urls) => {
    const zip = new JSZip();
    // console.log(urls);
    urls.map((item) => {
      if (item) {
        const n = item.name.split('.')[0];
        console.log(item.src);
        return zip.file(`${n}(${color}).png`, item.src.split(',')[1], {
          base64: true
        });
      }
      return 'null';
    });
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      saveAs(blob, filename);
    });
  };

  const checkImg = () => {
    const promises = [];
    if (checked)
      Object.entries(checked).map((item) => promises.push(doOverlay(item)));
    Promise.all(promises).then((res) => {
      saveZip('OverlayImages.zip', res);
      // console.log(res);
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper variant='outlined' className='aldg-formButtons'>
            <ToolTipIconButton
              color='success'
              onClick={handleSave}
              icon={faSave}
            />
            <ToolTipIconButton color='error' onClick={goBack} icon={faTimes} />
          </Paper>

          <Grid sx={{ padding: '3px 0' }} />

          <Card variant='outlined' className='aldg-formContainer'>
            <CardContent>
              {/* The Paper Wrapper provides useful card stylings for multiple sections. */}
              {/* <Paper variant='outlined' className='aldg-field'> */}
              <Grid container className='aldg-rootFormBox'>
                <Grid item xs={12}>
                  <h2>{STUBPAGE}: Image Overlay Application</h2>
                  Select color for Overlay:
                  <select
                    value={color.value}
                    onChange={handleChange}
                    defaultValue='black'
                    style={{
                      marginLeft: 30,
                      padding: 3,
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}
                  >
                    <option
                      value='black'
                      border='black'
                      style={{ color: 'black', fontWeight: 'bold' }}
                    >
                      Black
                    </option>
                    <option
                      value='blue'
                      border='blue'
                      style={{ color: 'blue', fontWeight: 'bold' }}
                    >
                      Blue
                    </option>
                    <option
                      value='lime'
                      border='lime'
                      style={{ color: 'lime', fontWeight: 'bold' }}
                    >
                      Lime
                    </option>
                    <option
                      value='magenta'
                      border='magenta'
                      style={{ color: 'magenta', fontWeight: 'bold' }}
                    >
                      Magenta
                    </option>
                    <option
                      value='red'
                      border='red'
                      style={{ color: 'red', fontWeight: 'bold' }}
                    >
                      Red
                    </option>
                    <option
                      value='white'
                      border='gray'
                      style={{ color: 'gray', fontWeight: 'bold' }}
                    >
                      White
                    </option>
                  </select>
                </Grid>
              </Grid>
              {/* </Paper> */}
            </CardContent>
          </Card>

          <Grid sx={{ padding: '3px 0' }} />

          <Card variant='outlined'>
            <CardContent>
              <Grid container spacing={1} justify='center' alignItems='center'>
                {URLArr.map(generateImg)}
              </Grid>
              <button
                type='button'
                style={{
                  border: 'none',
                  background: 'dodgerblue',
                  color: 'white',
                  borderRadius: 4,
                  padding: 5,
                  cursor: 'pointer'
                }}
                onClick={checkImg}
              >
                Download Image(s)
              </button>
              <h3>Generated Image(s):</h3>
              <Grid container spacing={1} justify='center' alignItems='center'>
                {imagesGen.map((item) => (
                  <img
                    src={item.src}
                    alt={item.name}
                    key={item.name}
                    style={{
                      width: '15%',
                      height: 'fit-content'
                    }}
                  />
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

Test.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};
Test.defaultProps = {};

export default Test;
