/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, useMediaQuery, createTheme, Grid } from '@mui/material';
import { useGridApiRef, LicenseInfo } from '@mui/x-data-grid-premium';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
  getDoc
} from 'firebase/firestore';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { firestore } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import { env } from '../../.env';

LicenseInfo.setLicenseKey(env.DATA_GRID_LICENSE);

const _StubList = (props) => {
  const { history } = props;
  const user = useContext(UserContext);
  const [Data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const GridApiRef = useGridApiRef();

  const STUBPAGE = '_Stub';
  const STUBCOLLECTION = 'Tasks';

  const addNew = () => {
    history.push(`/${STUBPAGE}/new`);
  };
  const CellSelect = (cell) => {
    if (cell.field === '__check__') return;
    history.push(`/${STUBPAGE}/${cell.id}`);
  };
  useEffect(() => {
    let mounted = true;

    const fRef = query(collection(firestore, `${STUBCOLLECTION}`));
    onSnapshot(fRef, (res) => {
      const all = [];
      res.forEach((d) => all.push(d.data()));
      if (mounted) setData(all);
    });
    return () => (mounted = false);
  }, []);

  const _theme = createTheme();
  const matches = useMediaQuery(_theme.breakpoints.down('sm'));
  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const columns = [
    {
      field: 'FIELD',
      headerName: 'Date',
      valueGetter: (params) => params.row.data.Date,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'FIELD1',
      headerName: 'Job',
      valueGetter: (params) => params.row.data.JobNumberDisplay,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'FIELD2',
      headerName: 'Foreman',
      valueGetter: (params) => params.row.data.ForemanDisplay,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];

  useEffect(() => {
    let mounted = true;
    if (mounted) setRows(Data);
    return () => (mounted = false);
  }, [Data]);

  const deleteRecords = () => {
    selection.forEach((s) => {
      deleteDoc(doc(firestore, `${STUBCOLLECTION}`, s));
    });
    setSelection([]);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <div style={{ paddingTop: '10px' }} />
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: matches ? '20px 0' : '10px 0',
            justifyContent: 'flex-end'
          }}
        >
          <ToolTipIconButton
            onClick={deleteRecords}
            color='error'
            icon={faTrash}
          />
          <ToolTipIconButton onClick={addNew} color='primary' icon={faPlus} />
        </div>
        <AldgDataGrid
          rows={rows}
          columns={columns}
        />
      </Grid>
    </Grid>
  );
};

_StubList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
_StubList.defaultProps = {};

export default _StubList;
